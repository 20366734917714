import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from '../Header';
import Footer from '../Footer';
import '../../styles/index.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SitePrismicQuery {
        prismicSiteSettings {
          data {
            site_name {
              text
            }
            site_description
            twitter_author
            twitter {
              url
            }
            facebook {
              url
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.prismicSiteSettings.data.site_name.text} />
        {children}
        <Footer data={data.prismicSiteSettings.data} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
